import { Link } from "gatsby";
import * as React from "react"
import styled from "styled-components";

import Layout from "../components/layout"
import Responsive from "../components/responsive";
import Seo from "../components/seo"

const Page = styled.div`
  ${Responsive};
  margin-top: 40px;
`;

const NotFoundPage = () => (
  <Layout transparent={false}>
    <Seo title="Page Not found" />
    <Page>
      <h1>Page Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist!</p>

      <p><Link to="/">Click here</Link> to go back home.</p>
    </Page>
  </Layout>
)

export default NotFoundPage
